import { useCallback, useMemo, useState } from 'react'
import type { ReservationHoldErrorResponse } from '@sevenrooms/core/domain'
import { useForm, useWatchMany } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { DateOnly, Formats, legacyJSDateAdapter } from '@sevenrooms/core/timepiece'
import { Form } from '@sevenrooms/core/ui-kit/form'
import { useMaxWidthBreakpoint, useThrottledResizeObserver } from '@sevenrooms/core/ui-kit/hooks'
import { Box, VStack, Window } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { ReservationHoldModal } from '../../../components/Modals/ResrvationHoldModal'
import { ReservationDayPickerForm } from '../../../components/ReservationDayPickerForm'
import {
  type AvailabilityTimeWithUpSellCost,
  useCachedCreateReservationHold,
  useLanguageStrings,
  useReservationNavigation,
  useVenue,
  useWidgetLanguage,
  useWidgetSettings,
} from '../../../hooks'
import { useExperiencesWithAvailability, type PrivateEventsExperience } from '../../../hooks/useAvailability'
import { reservationWidgetMessages } from '../../../reservationWidgetMessages'
import { useModals, useReservationFormState } from '../../../store'
import { finishSearchPage, selectTimeslot } from '../../../utils'
import { useListAvailabilityDatesQuery } from '../AvailabilityDateApi'
import { ExperienceDetailsModal } from '../ExperienceDetailsModal'
import { Experiences } from '../Experiences'
import { ReservationSearchInputGroup, StyledSearchInputGridContainer } from '../ReservationSearchInputGroup'
import { ReservationSelectForm } from '../ReservationSelectForm'
import { getPartySizeSelectOptions, getReservationHoldErrorMessage } from '../utils'
import { usePrivateEventsSearchFormSchema } from './PrivateEventsTab.zod'

interface PrivateEventsTabProps {
  showTabs: boolean
}

export function PrivateEventsTab({ showTabs }: PrivateEventsTabProps) {
  const { formatMessage } = useLocales()
  const { venueToday, urlKey, currencyCode, id: venueId } = useVenue()
  const { activeModal, showModal, hideModal, showErrorModal } = useModals()
  const { navigateNext: navigateNextStep, disableStep, enableStep } = useReservationNavigation()
  const { headerImgUrl, maxDaysOut, minGuests, maxGuests, reservationHoldEnabled } = useWidgetSettings()
  const { selectedLanguage } = useWidgetLanguage()
  const { isFetching: isFetchingLanguage } = useLanguageStrings()
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<AvailabilityTimeWithUpSellCost>()
  const [selectedExperience, setSelectedExperience] = useState<PrivateEventsExperience>()
  const isSmallDesktop = useMaxWidthBreakpoint('l')
  const isSmallMobile = useMaxWidthBreakpoint('xs')
  const { ref: reservationSearchInputGroupRef, width: reservationSearchInputGroup } = useThrottledResizeObserver(50, 'border-box')

  const currentDateAtVenue = DateOnly.fromSafe(venueToday)?.toJsDate() || new Date()

  const { isFetching: isDatesFetching, data: availabilityDates = [] } = useListAvailabilityDatesQuery({
    venue: urlKey,
    numDays: maxDaysOut,
    startDate: legacyJSDateAdapter(new Date(), Formats.MonthDayYearByDash),
  })

  const [createReservationHold] = useCachedCreateReservationHold()

  const isDayBlocked = useCallback((date: Date) => !availabilityDates.includes(DateOnly.fromDate(date).toIso()), [availabilityDates])

  const partySizeOptions = useMemo(
    () =>
      getPartySizeSelectOptions({
        minGuests,
        maxGuests,
      }),
    [maxGuests, minGuests]
  )

  const {
    formState: { ...searchFormData },
    updateFormState,
  } = useReservationFormState()

  const privateEventsSearchFormSchema = usePrivateEventsSearchFormSchema()
  const form = useForm(privateEventsSearchFormSchema, {
    defaultValues: {
      privateEventsPartySize: searchFormData.privateEventsPartySize,
      privateEventsStartDate: searchFormData.privateEventsStartDate
        ? DateOnly.from(searchFormData.privateEventsStartDate).toJsDate()
        : undefined,
    },
  })
  const { field, handleSubmit } = form
  const [privateEventsPartySize, privateEventsStartDate] = useWatchMany(field, ['privateEventsPartySize', 'privateEventsStartDate'])

  const { data: experiences, isFetching: isExperiencesFetching } = useExperiencesWithAvailability({
    venue: venueId,
    partySize: privateEventsPartySize || undefined,
    startDate: privateEventsStartDate ? DateOnly.fromDate(privateEventsStartDate).toIso() : undefined,
    clientId: searchFormData.clientId,
    selectedLangCode: selectedLanguage,
  })

  const onTimeSlotClick = useCallback(
    async (timeSlot: AvailabilityTimeWithUpSellCost, experience: PrivateEventsExperience) => {
      await handleSubmit(data => {
        const { privateEventsPartySize, privateEventsStartDate } = data
        if (!privateEventsPartySize || !privateEventsStartDate) {
          return
        }
        const date = DateOnly.fromDate(privateEventsStartDate).toIso()

        // update state when other availability is selected.
        updateFormState({
          privateEventsPartySize,
          privateEventsStartDate: date,
          selectedTimeSlot: timeSlot,
          selectedExperience: experience,
        })
        selectTimeslot(urlKey, date, timeSlot.time, privateEventsPartySize)
        if (timeSlot.upsellCategories && timeSlot.upsellCategories.length > 0) {
          enableStep('upgrades')
        } else {
          disableStep('upgrades')
        }
      })()
      setSelectedTimeSlot(timeSlot)
      setSelectedExperience(experience)
      showModal('moreExperienceDetails')
    },
    [disableStep, enableStep, handleSubmit, showModal, updateFormState, urlKey]
  )

  const onMoreDetailsClick = useCallback(
    (experience: PrivateEventsExperience) => {
      setSelectedExperience(experience)
      showModal('moreExperienceDetails')
    },
    [showModal]
  )

  const navigateNext = useCallback(
    async (timeslot: AvailabilityTimeWithUpSellCost) => {
      const { accessPersistentId, shiftPersistentId, time, timeIso } = timeslot
      const date = DateOnly.from(timeIso).toIso()
      finishSearchPage(urlKey)
      if (reservationHoldEnabled) {
        showModal('reservationHold')
        try {
          await createReservationHold({
            accessPersistentId,
            channel: 'SEVENROOMS_WIDGET',
            date,
            partySize: privateEventsPartySize as number,
            shiftPersistentId,
            time,
            venueId,
            trackingSlug: searchFormData.trackingSlug,
            clientId: searchFormData.clientId,
          }).unwrap()
        } catch (err) {
          const errorMessage = getReservationHoldErrorMessage(err as ReservationHoldErrorResponse, formatMessage)
          showErrorModal(errorMessage, undefined, true)
        }
        hideModal()
      }
      navigateNextStep()
    },
    [
      urlKey,
      reservationHoldEnabled,
      navigateNextStep,
      showModal,
      hideModal,
      createReservationHold,
      privateEventsPartySize,
      venueId,
      searchFormData.trackingSlug,
      searchFormData.clientId,
      showErrorModal,
      formatMessage,
    ]
  )

  const onAvailabilitySelect = useCallback(
    (timeSlot: AvailabilityTimeWithUpSellCost) => {
      navigateNext(timeSlot)
    },
    [navigateNext]
  )

  const onMoreDetailsModalClose = useCallback(() => {
    hideModal()
    setSelectedExperience(undefined)
    setSelectedTimeSlot(undefined)
  }, [hideModal])

  return (
    <>
      {showTabs && (
        <Box mt="sm" mb="m">
          <Text textStyle="h1">{formatMessage(reservationWidgetMessages.resWidgetPrivateEventsGroupBookingsTabHeader)}</Text>
        </Box>
      )}
      <VStack spacing="sm" mt={showTabs ? 'none' : 'm'}>
        <StyledSearchInputGridContainer>
          <Form {...form} onSubmit={() => {}} onInvalid={() => {}}>
            <ReservationSearchInputGroup columnsCount={2} ref={reservationSearchInputGroupRef}>
              <ReservationSelectForm
                id="reservation-party-size"
                label={formatMessage(reservationWidgetMessages.resWidgetGuestsLabel)}
                placeholder={formatMessage(reservationWidgetMessages.resWidgetPrivateEventsGroupBookingsPartySizePlaceholder)}
                dataTest="sr-reservation-party-size"
                location="left"
                isLoading={isFetchingLanguage}
                selectOptions={partySizeOptions}
                field={field.prop('privateEventsPartySize')}
                withEmpty
                onChange={value => {
                  updateFormState({ privateEventsPartySize: value })
                }}
              />
              <ReservationDayPickerForm
                label={formatMessage(reservationWidgetMessages.resWidgetDatePickerLabel)}
                isLoading={isFetchingLanguage || isDatesFetching}
                isDayBlocked={isDayBlocked}
                dataTest="sr-reservation-date"
                value={field.prop('privateEventsStartDate')}
                today={currentDateAtVenue}
                locale={selectedLanguage}
                popoverWidth={isSmallDesktop && !isSmallMobile ? undefined : reservationSearchInputGroup}
                onChange={(value: Date | undefined) => {
                  updateFormState({ privateEventsStartDate: value ? DateOnly.fromDate(value).toIso() : undefined })
                }}
                scrollTopOnMobile={!!headerImgUrl}
              />
            </ReservationSearchInputGroup>
          </Form>
        </StyledSearchInputGridContainer>
        <Experiences
          partySize={privateEventsPartySize ?? undefined}
          startDate={privateEventsStartDate ? DateOnly.fromDate(privateEventsStartDate).toIso() : undefined}
          experiences={experiences}
          isLoading={isExperiencesFetching}
          onTimeSlotClick={onTimeSlotClick}
          onMoreDetailsClick={onMoreDetailsClick}
        />
      </VStack>
      {activeModal === 'moreExperienceDetails' && selectedExperience && (
        <Window active>
          <ExperienceDetailsModal
            experience={selectedExperience}
            currencyCode={currencyCode}
            timeSlot={selectedTimeSlot}
            onClose={onMoreDetailsModalClose}
            onConfirmSelect={onAvailabilitySelect}
          />
        </Window>
      )}
      {activeModal === 'reservationHold' && (
        <Window active>
          <ReservationHoldModal />
        </Window>
      )}
    </>
  )
}
